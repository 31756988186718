import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import http from './utils/request'
import common from './utils/common'
import constant from './utils/constant'
import mavonEditor from 'mavon-editor'
import VueHead from 'vue-head';
Vue.use(VueHead);

//引入json配置
import EMOJI_DEFAULT from './assets/json/emoji.json' 
//引入js
// import './utils/live2d'
import './utils/title'
//引入css
import './assets/css/animation.css'
import './assets/css/index.css'
import './assets/css/tocbot.css'
import './assets/css/color.css'
import './assets/css/markdown-highlight.css'
import './assets/css/font-awesome.min.css'
import 'mavon-editor/dist/css/index.css'
//点击涟漪效果
import Ripple from 'vue-ripple-directive'
import {vueBaberrage} from 'vue-baberrage'
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  lazyComponent: true,
  loading: 'https://cos.roginx.ink/load.gif'
})
Ripple.color = 'var(--rippleColor)'
Vue.directive("ripple", Ripple)
Vue.use(ElementUI)
Vue.use(vueBaberrage)
Vue.use(mavonEditor)
Vue.use(store)
Vue.prototype.EMOJI = EMOJI_DEFAULT

Vue.prototype.$http = http
Vue.prototype.$common = common
Vue.prototype.$constant = constant
Vue.config.productionTip = false

Vue.prototype.global = {
  showVideoPoster:false,
}
top.faceReg = common.faceReg;

// import 'viewerjs/dist/viewer.css'
// import VueViewer from 'v-viewer'
// Vue.use(VueViewer)

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
Vue.directive('drag',  {
  bind(el) {
    let position = { x: 0, y: 0 };
    let lastPos = { x: 0, y: 0 };
 
    function updatePosition(clientX, clientY) {
      const dx = clientX - lastPos.x;
      const dy = clientY - lastPos.y;
      position.x += dx;
      position.y += dy;
      el.style.transform  = `translate(${position.x}px, ${position.y}px)`;
      lastPos.x = clientX;
      lastPos.y = clientY;
    }
 
    function handleMove(e) {
      const coords = e.touches  ? e.touches[0]  : e;
      updatePosition(coords.clientX,  coords.clientY); 
      if(e.cancelable)  e.preventDefault();  // 防止手机端滚动[4]()
    }
 
    function handleEnd(e) {
      const coords = e.changedTouches  ? e.changedTouches[0]  : e;
      updatePosition(coords.clientX,  coords.clientY); 
      document.body.style.userSelect  = '';
      document.removeEventListener('mousemove',  handleMove);
      document.removeEventListener('touchmove',  handleMove);
      document.removeEventListener('mouseup',  handleEnd);
      document.removeEventListener('touchend',  handleEnd);
    }
 
    function startDrag(e) {
      e.stopPropagation(); 
      document.body.style.userSelect  = 'none';
      const initEvent = e.touches  ? e.touches[0]  : e;
      lastPos.x = initEvent.clientX; 
      lastPos.y = initEvent.clientY; 
      
      document.addEventListener('mousemove',  handleMove);
      document.addEventListener('touchmove',  handleMove, { passive: false });
      document.addEventListener('mouseup',  handleEnd);
      document.addEventListener('touchend',  handleEnd);
    }
 
    el.addEventListener('mousedown',  startDrag);
    el.addEventListener('touchstart',  startDrag, { passive: true });
  }
});
console.log('%c hello world! \n Print by Roginshin. ', 
'background: #ed556a;line-height:50px;font-size:24px; color: #f6cec1;font-family:var(--globalFont)');