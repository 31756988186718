<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { getAlistContent, getValue } from './utils/api.js';
export default {
  data() {
    return {};
  },

  mounted() {
    this.fetchSettings();
    this.fetchWallpaperContent();
  },

  methods: {
    fetchSettings() {
      getValue({ id: 2 }).then((res) => {
        if (res.code === 200) {
          const settings = res.data.value;

          localStorage.setItem("PLAYLIST_LIST", JSON.stringify(settings.PLAYLIST_LIST));
          localStorage.setItem("COLLECTS_FLODER", JSON.stringify(settings.COLLECTS_FLODER));
          localStorage.setItem("DEFAULT_WALLPAPER", JSON.stringify(settings.DEFAULT_WALLPAPER));
          localStorage.getItem("favoriteWallpaper") || localStorage.setItem("favoriteWallpaper", JSON.stringify(settings.favoriteWallpaper));

          this.$constant.playlists = settings.playlist;

          // Randomly change or push playlist
          this.$nextTick(() => {
            const playlistAction = Math.random() < 0.5 ? "changePlaylist" : "pushPlaylist";
            const randomPlaylist = this.$constant.playlists[Math.floor(Math.random() * this.$constant.playlists.length)];
            this.$store.commit(playlistAction, randomPlaylist);
          });

          if (!localStorage.getItem("emoji")) {
            this.EMOJI = [...this.EMOJI, ...settings.emoji];
            localStorage.setItem("emoji", JSON.stringify(this.EMOJI));
          }

          this.$store.state.fonts = settings.fonts;
          this.loadFont(localStorage.getItem("fontFamilyName") || settings.fonts[2].name);
        }
      });
    },

    fetchWallpaperContent() {
      getAlistContent({ path: "/wallpaper", password: "", page: 1, per_page: 20, refresh: false })
        .then((res) => {
          window.localStorage.setItem("alistSource_wallpaperFolder", JSON.stringify(res.data.content));
        })
        .catch((error) => {
          console.error(error);
        });
    },

    loadFont(fontFamily) {
      const fontFamilyObj = this.$store.state.fonts.find((font) => font.name === fontFamily);
      const fontUrl = fontFamilyObj?.href;

      if (fontUrl) {
        const fontLink = document.createElement('link');
        fontLink.setAttribute('rel', 'stylesheet');
        fontLink.setAttribute('href', fontUrl);
        document.head.appendChild(fontLink);
        document.documentElement.style.setProperty('--globalFont', fontFamilyObj?.import);
      }
    }
  }
};
</script>

<style scoped>
</style>
